@import "~@/styles/variables/variables.scss";














































































































































































































.page.tenant-manage {
}
.page-main {
	height: 100%;
}
.page-main-right {
	//display: flex;
	//flex-direction: column;
	height: 100%;
	//flex: 1;
	overflow: hidden;
	padding-bottom: 0;

	box-sizing: border-box;
	.table-area {
		margin-top: 22px;
		height: calc(100% - 57px);
	}
}
::v-deep {
	.gc-table {
		.grant {
			font-size: 14px;
			color: #4d6bff;
			line-height: 21px;
			cursor: pointer;
		}
	}
}
